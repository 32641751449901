<template>
  <v-dialog
    transition="slide-x-transition"
    class="roller"
    width="800"
    height="700"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="opened"
  >
    <v-card width="800" class="roller">
      <v-flex class="roller" xs12>
        <div class="expande-horizontal wrap roller">
          <v-flex xs12>
            <div
              style="background: #fff; border: none;"
              class="windows-style expande-horizontal centraliza"
            >
              <v-list color="transparent" width="100%" class="pa-0 ma-0">
                <v-list-item class="px-0 mx-0">
                  <v-avatar class="mr-2" size="40" color="#fff" @click="close">
                    <v-icon :color="$theme.primary">mdi-arrow-left</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <h3 class="fonte font-weight-bold">
                      Mesas
                    </h3>
                    <span class="fonte fonteMini grey--text">
                      Mesas laranjadas estão ocupadas e azul disponiveis, os
                      botões verdes abrem pedidos separados de uma mesma mesa
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-flex>
          <v-flex
            v-for="(mesa, index) in mesas_disponiveis"
            :key="`id-mesa-${index}`"
            xs4
            v-show="mesa.active"
            md2
            class="pa-3"
          >
            <v-card
              hover
              dark
              class="fonte elevation-0"
              :class="{
                'cardMesa-available': isAvailable(mesa),
                'cardMesa-unavailable': !isAvailable(mesa)
              }"
            >
              <v-flex xs12>
                <div
                  class="expande-horizontal centraliza column"
                  :class="{ 'pt-0 pb-0': $vuetify.breakpoint.smAndUp }"
                >
                  <div
                    @click="selecionarMesa(mesa, save)"
                    class="expande-horizontal centraliza column pt-3"
                  >
                    <h1
                      @click="selecionarMesa(mesa, save)"
                      class="fonte fonteMini white--text mb-2"
                    >
                      {{ mesa.number }}
                    </h1>
                    <span
                      v-if="
                        clientsInThisTable(mesa) &&
                          clientsInThisTable(mesa).length <= 1
                      "
                      @click="selecionarMesa(mesa, save)"
                      class="fonte fonteMini white--text"
                    >
                      {{ mesa.description }}
                    </span>
                    <span
                      v-if="
                        clientsInThisTable(mesa) &&
                          clientsInThisTable(mesa).length <= 1
                      "
                      @click="selecionarMesa(mesa, save)"
                      class="fonte font-weight-light fonteMini pb-2 mt-2 white--text"
                    >
                      ( {{ isAvailable(mesa) ? "disponível" : "ocupada" }} )
                    </span>
                  </div>
                  <div
                    v-for="(pedido, i) in clientsInThisTable(mesa) || []"
                    :key="`table-client-${pedido._id}`"
                    @click="selecionarMesa(mesa, save, false, pedido)"
                    :style="
                      `background: ${$theme.third}; border-bottom: 1px dashed #f2f2f2; border-top: 1px dashed #f2f2f2;`
                    "
                    v-show="!isAvailable(mesa)"
                    class="expande-horizontal centraliza column"
                  >
                    <span class="fonte fonteMini my-2 white--text">
                      {{
                        pedido.compra.cliente
                          ? pedido.compra.cliente.nome
                          : `cliente ${i + 1}`
                      }}
                    </span>
                  </div>
                  <div
                    @click="selecionarMesa(mesa, save, true, {})"
                    :style="`background: ${$theme.secondary}`"
                    v-if="!isAvailable(mesa)"
                    class="expande-horizontal centraliza column"
                  >
                    <span class="fonte fonteMini my-2 white--text">
                      + cliente
                    </span>
                  </div>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/main.js";

export default {
  props: {
    mesas_disponiveis: {
      type: Array,
      required: true
    },
    selecionarMesa: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      opened: false,
      save: false
    };
  },
  computed: {
    ...mapGetters(["get_gestordepedidos"])
  },
  methods: {
    ...mapActions(["createGlobalMessage", "listar_gestordepedidos"]),
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    clientsInThisTable(table) {
      const isInThisTable = pedido => {
        const assert1 =
          pedido.compra.tipo === "mesa" &&
          pedido.status_do_pedido !== "concluído";
        const assert2 = assert1
          ? pedido.compra.mesa && pedido.compra.mesa.number === table.number
          : false;
        if (assert1 && assert2) {
          return true;
        }
        return false;
      };
      const hasTableInThisTable = this.get_gestordepedidos.docs.filter(pedido =>
        isInThisTable(pedido)
      );
      return hasTableInThisTable || [];
    },
    isAvailable(table) {
      const isInThisTable = pedido => {
        const assert1 = pedido.compra && pedido.compra.tipo === "mesa";
        let assert2 = false;
        if (pedido.compra && pedido.compra.mesa) {
          assert2 = assert1
            ? pedido.compra.mesa &&
              pedido.compra.mesa.number === table.number &&
              pedido.status_do_pedido !== "concluído"
            : false;
        }
        if (assert1 && assert2) {
          return true;
        }
        return false;
      };
      const hasTableInThisTable = this.get_gestordepedidos.docs.filter(pedido =>
        isInThisTable(pedido)
      );
      if (hasTableInThisTable && hasTableInThisTable.length > 0) {
        return false;
        //is not available
      } else {
        return true;
        //is available
      }
    }
  },
  created() {
    setInterval(() => {
      this.listar_gestordepedidos("quiet");
    }, 10000);
  }
};
</script>

<style scoped>
.item-list {
  background: #f0f0f0;
  border-radius: 6px;
}
.roller::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.roller::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.roller::-webkit-scrollbar-thumb {
  background-color: #673ab7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-sized {
  font-size: 1rem;
}
.font--mini {
  font-size: 0.7rem;
}
.cardMesa-available {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673ab7,
    #2196f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7, #2196f3);
}
.cardMesa-unavailable {
  background: #e1673c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e1673c,
    #e45c2a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e1673c, #eb5721);
}
</style>
