<template>
  <v-dialog
    transition="slide-x-transition"
    style="overflow: hidden; z-index: 100"
    persistent
    class="expande-horizontal centraliza pt-0 mt-0"
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : true"
    v-model="opened"
  >
    <div class="expande-horizontal vitrify centraliza pa-0 ma-0">
      <v-card
        :style="
          `min-height: 500px; ${
            $vuetify.breakpoint.smAndUp ? 'max-height: 500px;' : ''
          }; overflow: hidden;`
        "
        width="800"
        class="pb-0 pt-0 mt-0 pr-0"
      >
        <v-flex class="pa-0 ma-0" xs12>
          <div class="expande-horizontal wrap pa-0 ma-0" style="height: 100%;">
            <v-flex style="background: #333;" xs12 md6>
              <div class="expande-horizontal">
                <!-- <v-carousel
                  cycle
                  :height="$vuetify.breakpoint.smAndDown ? '377' : '520'"
                  width="100%"
                  class="pa-0 ma-0"
                  hide-delimiter-background
                  show-arrows-on-hover
                >
                  <v-carousel-item
                    v-for="(slide, i) in product.imagens"
                    :style="
                      `background: url(${slide}); background-size: 100 100;`
                    "
                    :key="i"
                  >
                    <v-img
                      class="fume"
                      style="background-size: 0 100"
                      contain
                      center
                      :style="
                        `height: ${
                          $vuetify.breakpoint.smAndDown ? '377' : '520'
                        }px;width: 100%;`
                      "
                      :src="slide"
                    />
                  </v-carousel-item>
                </v-carousel> -->
                <v-img
                  class="fume"
                  style="background-size: 0 100"
                  contain
                  center
                  :style="
                    `height: ${
                      $vuetify.breakpoint.smAndDown ? '480' : '500'
                    }px;width: 100%;`
                  "
                  :src="product.image"
                />
              </div>
            </v-flex>
            <v-flex class="pt-0 ma-0" xs12 md6>
              <div class="expande-horizontal">
                <v-flex class="mb-1 pl-0 pt-0" xs12>
                  <v-list class="pa-0" dense color="transparent">
                    <v-list-item
                      class="pa-0 ma-0 pr-2 pl-3 fonte"
                      :style="`margin-bottom: 1px solid #333;`"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          :style="`color: ${$theme.primary}`"
                          class="font-weight-bold"
                          >{{ product.nome }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          class="mb-1 fonte-micro grey--text"
                        >
                          {{ product.descricao }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="product.type !== 'oferta'"
                          class="fonteMini grey--text"
                        >
                          {{ $helper.formataSaldo(product.preco_com_markup) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pr-3 pb-1">
                        <v-avatar
                          style="cursor: pointer;"
                          size="27"
                          :color="$theme.primary"
                          @click.prevent="close"
                          icon
                          class="mr-0"
                        >
                          <v-icon color="white">mdi-close</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
              <v-flex
                xs12
                class="pl-2 pr-2 pt-0 adicionals"
                style="min-height: 401px; overflow: auto;"
              >
                <!-- <div class="expande-horizontal">
                  <h4 class="orange--text fonte pt-0 pb-3">adicionais:</h4>
                </div> -->
                <div
                  v-if="product.type == 'oferta' && product.default_offer"
                  class="expande-horizontal column wrap"
                >
                  <h4 class="fonte" v-if="product.default_offer">
                    Este produto tem
                    {{ product.default_offer.prices.length }} opções de preço,
                    escolha abaixo.
                  </h4>
                  <div class="expande-horizontal wrap">
                    <div
                      v-for="(price, i) in product.default_offer.prices"
                      :key="`price-offer-${i}`"
                      class="fonte item-price-offer"
                      @click="selectPriceOffer(price)"
                      :class="{
                        'item-price-offer-selected':
                          price.description ===
                          (product.offer_selected &&
                            product.offer_selected.description)
                      }"
                    >
                      <span>{{ $helper.formataSaldo(price.value || 0) }}</span>
                      <span class="fonteMini grey--text">{{
                        price.description
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="expande-horizontal wrap">
                  <v-flex xs12>
                    <v-list
                      class="pa-0 ma-0"
                      v-for="(adicional, index) in product.adicionais"
                      :id="`aditional_${index}`"
                      :key="adicional.nome_grupo"
                      color="transparent"
                    >
                      <v-list-item class="pa-0 ma-0 pl-3 fonte item-list">
                        <v-list-item-content>
                          <v-list-item-title class="pb-1 font-weight-bold">
                            {{ adicional.nome_grupo }}
                            <!-- <v-btn
                              class="ml-3 elevation-0"
                              color="white"
                              x-small
                              >{{
                                adicional.obrigatorio ? "obrigatório" : "opcional"
                              }}</v-btn
                            > -->
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte-micro">
                            {{ adicional.quantidade_adicionada || 0 }} de
                            {{ adicional.maximo }} itens adicionados
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn class="mr-3" icon dark>
                            <v-icon
                              size="21"
                              :color="$theme.primary"
                              v-if="adicional.obrigatorio"
                              >mdi-asterisk-circle-outline</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <template v-for="(item_adicional, i) in adicional.itens">
                        <v-list-item class="fonte" :key="item_adicional.nome">
                          <v-list-item-content>
                            <v-list-item-title class="fonte black--text">
                              {{
                                item_adicional.descricao_personalizada
                                  ? item_adicional.descricao_personalizada
                                  : item_adicional.nome
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="fonte grey--text fonteMini"
                            >
                              + {{ $helper.formataSaldo(item_adicional.preco) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div class="expande-horizontal">
                              <v-btn
                                @click.prevent="removeAditional(i, index)"
                                v-if="
                                  item_adicional &&
                                    item_adicional.quantidade_adicionada > 1
                                "
                                icon
                                x-small
                              >
                                <v-icon>mdi-minus-circle-outline</v-icon>
                              </v-btn>
                              <v-btn
                                @click.prevent="removeAditional(i, index)"
                                v-if="
                                  item_adicional &&
                                    item_adicional.quantidade_adicionada === 1
                                "
                                icon
                                x-small
                                style="margin-top: 2px;"
                              >
                                <v-icon>mdi-delete-circle-outline</v-icon>
                              </v-btn>
                              <div style="height: 40px; padding-bottom: 6px;">
                                <span class="mx-6 mb-1 fonte orange--text">
                                  {{
                                    item_adicional.quantidade_adicionada || 0
                                  }}
                                </span>
                              </div>
                              <v-btn
                                @click="addAditional(i, index)"
                                icon
                                x-small
                                style="margin-top: 2px;"
                              >
                                <v-icon color="green"
                                  >mdi-plus-circle-outline</v-icon
                                >
                              </v-btn>
                            </div>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- <v-divider
                          :key="`list-adicional-divider-${i}-`"
                        ></v-divider> -->
                      </template>
                    </v-list>
                  </v-flex>
                  <v-flex
                    class="pt-6"
                    :class="{ 'pb-12': $vuetify.breakpoint.smAndDown }"
                    xs12
                  >
                    <v-textarea
                      filled
                      height="100px"
                      @keyup.enter.prevent="addProduct"
                      hint="Pressione enter para adicionar rapidamente"
                      v-model="product.obs"
                      :color="$theme.primary"
                      label="Observações"
                    ></v-textarea>
                  </v-flex>
                </div>
              </v-flex>
              <div
                v-if="$vuetify.breakpoint.mdAndUp"
                class="pl-6 expande-horizontal"
                style="background: #f2f2f2; position: absolute; bottom: 0; right: 0; border-top: 1px dashed #e5e5e5;"
              >
                <v-btn
                  @click.prevent="removeProductQtd"
                  v-if="product.quantity > 1"
                  icon
                  :color="$theme.secondary"
                  small
                  class="mt-2"
                >
                  <v-icon size="27">mdi-minus-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  @click.prevent="removeProductOfCart(product)"
                  v-if="product.quantity === 1"
                  icon
                  :color="$theme.primary"
                  small
                  class="mt-2"
                >
                  <v-icon size="27">mdi-delete-circle-outline</v-icon>
                </v-btn>
                <h3 class="mx-7 mt-2 fonte font-weight-bold">
                  {{ product.quantity }}
                </h3>
                <v-btn class="mt-2" @click="addProductQtd" icon small>
                  <v-icon size="27" :color="$theme.secondary"
                    >mdi-plus-circle-outline</v-icon
                  >
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  @click.prevent="addProduct"
                  :color="$theme.primary"
                  height="40"
                  class="fonte elevation-0 font-weight-bold"
                  dark
                >
                  {{ $vuetify.breakpoint.smAndDown ? "+" : "Adicionar" }}
                  {{ $helper.formataSaldo(product.seller_price) }}
                </v-btn>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-card>
    </div>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      bottom
      class="pl-6 expande-horizontal elevation-0"
      style="border-top: 1px dashed #e5e5e5; bottom: 0px;"
    >
      <v-btn
        @click.prevent="removeProductQtd"
        v-if="product.quantity > 1"
        icon
        small
      >
        <v-icon size="27" :color="$theme.secondary"
          >mdi-minus-circle-outline</v-icon
        >
      </v-btn>
      <v-btn
        @click.prevent="removeProductOfCart(product)"
        v-if="product.quantity === 1"
        icon
        small
      >
        <v-icon size="32" :color="$theme.primary"
          >mdi-delete-circle-outline</v-icon
        >
      </v-btn>
      <h3 class="pt-3 mt-1 mx-3 fonte font-weight-bold">
        {{ product.quantity }}
      </h3>
      <v-btn @click="addProductQtd" icon small>
        <v-icon size="32" :color="$theme.secondary"
          >mdi-plus-circle-outline</v-icon
        >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        @click.prevent="addProduct"
        :color="$theme.primary"
        class="fonte fonte-bold white--text"
        dark
      >
        <span class="white--text font-weight-bold">
          {{ $vuetify.breakpoint.smAndDown ? "+" : "Adicionar" }}
          {{ $helper.formataSaldo(product.seller_price) }}
        </span>
      </v-btn>
    </v-bottom-navigation>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    addProductToCart: {
      type: Function,
      requiblue: true
    },
    removeProductOfCart: {
      type: Function,
      requiblue: true
    }
  },
  data() {
    return {
      opened: false,
      product: {
        quantidade: 1,
        imagens: []
      },
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "blue lighten-1",
        "deep-purple accent-4"
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"]
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    selectPriceOffer(price) {
      this.product.offer_selected = price;
      this.product.preco_com_markup = price.value;
      this.processFinalPrice();
    },
    open(product) {
      this.opened = true;
      this.product = {
        ...product,
        quantity: product.quantity ? product.quantity : 1,
        seller_price: product.quantity
          ? product.seller_price
          : product.preco_com_markup
      };
      this.processFinalPrice();
      // if (
      //   !product.adicionais ||
      //   (product.adicionais && product.adicionais.length === 0)
      // ) {
      //   this.addProductToCart(this.product);
      // }
    },
    close() {
      this.opened = false;
      this.product = { quantity: 1 };
    },
    addAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada < parseInt(item_adicional.maximo)
      ) {
        item_adicional.quantidade_adicionada++;
        item_adicional.itens[index_item_adicional].quantidade_adicionada
          ? item_adicional.itens[index_item_adicional].quantidade_adicionada++
          : (item_adicional.itens[
              index_item_adicional
            ].quantidade_adicionada = 1);
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === parseInt(item_adicional.maximo)
      ) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: "Opa, quantidade máxima atingida!",
          timeout: 6000
        });
      } else if (!item_adicional.quantidade_adicionada) {
        item_adicional.quantidade_adicionada = 1;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 1;
      }

      this.product.adicionais[index_adicional] = item_adicional;
      this.$forceUpdate();
      this.processFinalPrice();
    },
    removeAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada > 1
      ) {
        item_adicional.quantidade_adicionada--;
        item_adicional.itens[index_item_adicional].quantidade_adicionada--;
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === 1
      ) {
        item_adicional.quantidade_adicionada = 0;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 0;
      }
      this.product.adicionais[index_adicional] = item_adicional;
      this.processFinalPrice();
    },
    // thiss process product.seller_price
    processFinalPrice() {
      const aditionals = this.product.adicionais || [];
      let initial_price = parseFloat(this.product.preco_com_markup);
      let aditional_total_price = 0;
      aditionals.map(adicional => {
        adicional.itens.map(item => {
          if (item.quantidade_adicionada) {
            aditional_total_price +=
              parseInt(item.quantidade_adicionada) * parseFloat(item.preco);
          }
        });
      });
      this.product.seller_price =
        this.product.quantity * (initial_price + aditional_total_price);
      this.product.aditional_total_price = aditional_total_price;
      this.$forceUpdate();
    },
    addProduct() {
      const product = this.product;
      if (product.type && product.type == 'oferta' && (!product.preco_com_markup || product.preco_com_markup <= 0)) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: "Selecione uma oferta do produto!",
          timeout: 6000
        }) 
        return
      }
      let ok = true;
      let ok_group = "";
      if (product.adicionais && product.adicionais.length) {
        product.adicionais.map(aditional => {
          if (aditional.obrigatorio) {
            if (!aditional.quantidade_adicionada) {
              ok = false;
              ok_group = aditional.nome_grupo;
            } else if (
              aditional.quantidade_adicionada &&
              aditional.quantidade_adicionada < 1
            ) {
              ok = false;
              ok_group = aditional.nome_grupo;
            }
          }
        });
      }
      if (!ok) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: `Opa, o adicional de ${ok_group} é obrigatório!`,
          timeout: 6000
        });
      } else {
        this.addProductToCart(product);
      }
    },
    addProductQtd() {
      this.product.quantity++;
      this.processFinalPrice();
    },
    removeProductQtd() {
      if (this.product.quantity > 1) {
        this.product.quantity--;
      }
      this.processFinalPrice();
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #f8f7f7;
  border-radius: 3px;
}
.adicionals::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.adicionals::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.adicionals::-webkit-scrollbar-thumb {
  background-color: #6e6d6d; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-green {
  color: #673ab7;
}
.item-price-offer {
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #e5e5e5;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 6px;
  cursor: pointer;
}
.item-price-offer-selected {
  background: #6b25d4 !important;
  color: #333 !important;
  span {
    color: #f2f2f2 !important;
  }
}
</style>
