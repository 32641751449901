<template>
  <v-dialog
    transition="slide-x-transition"
    class="roller"
    width="800"
    height="700"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="opened"
  >
    <v-card width="800" class="roller">
      <v-flex class="roller" xs12>
        <div class="expande-horizontal wrap roller">
          <v-flex xs12>
            <div class="windows-style expande-horizontal centraliza">
              <v-list color="transparent" dense width="100%" class="pa-0 ma-0">
                <v-list-item>
                  <v-avatar
                    class="mr-3"
                    size="27"
                    :color="$theme.primary"
                    @click="close"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="fonte fonteMini font-weight-bold">
                      Lista de mesas
                    </v-list-item-title>
                    <!-- <span class="fonte fonteMini grey--text">
                      Mesas laranjadas estão ocupadas e azul disponiveis
                    </span> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-flex>
          <v-flex
            v-for="(mesa, index) in mesas_disponiveis"
            :key="`id-mesa-${index}`"
            xs4
            v-show="mesa.active"
            md2
            class="pa-3"
          >
            <v-card
              @click="selecionarMesa(mesa, save)"
              hover
              dark
              class="fonte elevation-0"
              :class="{
                'cardMesa-available': isAvailable(mesa),
                'cardMesa-unavailable': !isAvailable(mesa)
              }"
            >
              <v-flex xs12>
                <div
                  class="expande-horizontal centraliza column pt-3 pb-3"
                  :class="{ 'pt-6 pb-6': $vuetify.breakpoint.smAndUp }"
                >
                  <h1 class="fonte fonteMini white--text">{{ mesa.number }}</h1>
                  <span class="fonte fonteMini mt-2 white--text">
                    {{ mesa.description }}
                  </span>
                  <span class="fonte font-weight-light fonteMini mt-2 white--text">
                    ( {{ isAvailable(mesa) ? "disponível" : "ocupada" }} )
                  </span>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    mesas_disponiveis: {
      type: Array,
      required: true
    },
    selecionarMesa: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      opened: false,
      save: false
    };
  },
  computed: {
    ...mapGetters(["get_gestordepedidos"])
  },
  methods: {
    ...mapActions(["createGlobalMessage", "listar_gestordepedidos"]),
    open() {
      this.listar_gestordepedidos();
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.listar_gestordepedidos();
    },
    isAvailable(table) {
      const isInThisTable = pedido => {
        const assert1 = pedido.compra.tipo === "mesa";
        let assert2 = false;
        if (pedido.compra.mesa) {
          assert2 = assert1
            ? pedido.compra.mesa && pedido.compra.mesa.number === table.number
            : false;
        }
        if (assert1 && assert2) {
          return true;
        }
        return false;
      };
      const hasTableInThisTable = this.get_gestordepedidos.docs.filter(pedido =>
        isInThisTable(pedido)
      );
      if (hasTableInThisTable.length > 0) {
        return false;
        //is not available
      } else {
        return true;
        //is available
      }
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #f0f0f0;
  border-radius: 6px;
}
.roller::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.roller::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.roller::-webkit-scrollbar-thumb {
  background-color: #673AB7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.font-sized {
  font-size: 1rem;
}
.font--mini {
  font-size: 0.7rem;
}
.cardMesa-available {
  background: #673AB7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673AB7,
    #2196f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673AB7, #2196f3);
}
.cardMesa-unavailable {
  background: #e1673c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e1673c,
    #e45c2a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e1673c, #eb5721);
}
</style>
