var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"roller",attrs:{"transition":"slide-x-transition","width":"800","height":"700","persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-card',{staticClass:"roller",attrs:{"width":"800"}},[_c('v-flex',{staticClass:"roller",attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal wrap roller"},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"windows-style expande-horizontal centraliza",staticStyle:{"background":"#fff","border":"none"}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"color":"transparent","width":"100%"}},[_c('v-list-item',{staticClass:"px-0 mx-0"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"40","color":"#fff"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"color":_vm.$theme.primary}},[_vm._v("mdi-arrow-left")])],1),_c('v-list-item-content',[_c('h3',{staticClass:"fonte font-weight-bold"},[_vm._v(" Mesas ")]),_c('span',{staticClass:"fonte fonteMini grey--text"},[_vm._v(" Mesas laranjadas estão ocupadas e azul disponiveis, os botões verdes abrem pedidos separados de uma mesma mesa ")])])],1)],1)],1)]),_vm._l((_vm.mesas_disponiveis),function(mesa,index){return _c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(mesa.active),expression:"mesa.active"}],key:`id-mesa-${index}`,staticClass:"pa-3",attrs:{"xs4":"","md2":""}},[_c('v-card',{staticClass:"fonte elevation-0",class:{
              'cardMesa-available': _vm.isAvailable(mesa),
              'cardMesa-unavailable': !_vm.isAvailable(mesa)
            },attrs:{"hover":"","dark":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"expande-horizontal centraliza column",class:{ 'pt-0 pb-0': _vm.$vuetify.breakpoint.smAndUp }},[_c('div',{staticClass:"expande-horizontal centraliza column pt-3",on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save)}}},[_c('h1',{staticClass:"fonte fonteMini white--text mb-2",on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save)}}},[_vm._v(" "+_vm._s(mesa.number)+" ")]),(
                      _vm.clientsInThisTable(mesa) &&
                        _vm.clientsInThisTable(mesa).length <= 1
                    )?_c('span',{staticClass:"fonte fonteMini white--text",on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save)}}},[_vm._v(" "+_vm._s(mesa.description)+" ")]):_vm._e(),(
                      _vm.clientsInThisTable(mesa) &&
                        _vm.clientsInThisTable(mesa).length <= 1
                    )?_c('span',{staticClass:"fonte font-weight-light fonteMini pb-2 mt-2 white--text",on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save)}}},[_vm._v(" ( "+_vm._s(_vm.isAvailable(mesa) ? "disponível" : "ocupada")+" ) ")]):_vm._e()]),_vm._l((_vm.clientsInThisTable(mesa) || []),function(pedido,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAvailable(mesa)),expression:"!isAvailable(mesa)"}],key:`table-client-${pedido._id}`,staticClass:"expande-horizontal centraliza column",style:(`background: ${_vm.$theme.third}; border-bottom: 1px dashed #f2f2f2; border-top: 1px dashed #f2f2f2;`),on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save, false, pedido)}}},[_c('span',{staticClass:"fonte fonteMini my-2 white--text"},[_vm._v(" "+_vm._s(pedido.compra.cliente ? pedido.compra.cliente.nome : `cliente ${i + 1}`)+" ")])])}),(!_vm.isAvailable(mesa))?_c('div',{staticClass:"expande-horizontal centraliza column",style:(`background: ${_vm.$theme.secondary}`),on:{"click":function($event){return _vm.selecionarMesa(mesa, _vm.save, true, {})}}},[_c('span',{staticClass:"fonte fonteMini my-2 white--text"},[_vm._v(" + cliente ")])]):_vm._e()],2)])],1)],1)})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }