<template>
  <v-dialog fullscreen transition="slide-x-transition" v-model="modal">
    <div class="expande-horizontal expande-vertical-vh vitrify centraliza">
      <v-flex xs12 md10>
        <div class="animate__animated expande-horizontal column">
          <div class="expande-horizontal centraliza wrap py-6 pb-0">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-card
                  color=""
                  class="expande-horizontal"
                  style="min-height: 77vh;"
                >
                  <div class="expande-horizontal">
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="pa-6"
                      xs12
                      md6
                    >
                      <div
                        style="min-height: 60vh;"
                        class="expande-horizontal pa-6 centraliza"
                      >
                        <LottieAnimation
                          v-if="loading === 'loading_rocket'"
                          class="pa-6"
                          ref="anim"
                          :loop="true"
                          :animationData="
                            require('@/apps/shared/assets/loading_rocket.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'success'"
                          class="pa-6"
                          ref="anim2"
                          :animationData="
                            require('@/apps/shared/assets/activate_account_success.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'man_typing'"
                          :loop="true"
                          class="pa-6 animate__fadeInUp"
                          ref="anim3"
                          :animationData="
                            require('@/apps/shared/assets/expense.json')
                          "
                        />
                      </div>
                    </v-flex>
                    <v-flex class="pa-6" xs12 md6>
                      <v-window touchless v-model="step">
                        <v-window-item :value="1">
                          <div
                            style="min-height: 57vh;"
                            transition="fade"
                            class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                          >
                            <v-flex class="pa-5 pb-2" xs12>
                              <div
                                class="expande-horizontal column animate__fadeIn"
                              >
                                <h1
                                  v-if="get_compra"
                                  class="fonte"
                                  style="color: #2cc4ea;"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      parseFloat(get_compra.preco_total) -
                                        (get_compra.total_recebido || 0)
                                    )
                                  }}
                                </h1>
                                <span class="fonte grey--text"
                                  >Como deseja pagar?</span
                                >
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <v-flex xs12 class="">
                                <v-list
                                  v-if="loading === 'man_typing'"
                                  nav
                                  two-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <v-list-item
                                    :class="
                                      indexByArrow === 0
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    @click="selectPaymentMethod('SCN')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        SCN
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 0
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.money.active"
                                    @click="selectPaymentMethod('dinheiro')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        1 Dinheiro
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 1
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.pix.active"
                                    @click="selectPaymentMethod('dinheiro')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-cash</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        2 Pix
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 2
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.card.active"
                                    @click="selectPaymentMethod('credito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        3 Crédito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    :class="
                                      indexByArrow === 3
                                        ? 'paymentMethodPreSelected'
                                        : ''
                                    "
                                    v-if="payment_sys_config.debit.active"
                                    @click="selectPaymentMethod('debito')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="21"
                                      color="green"
                                      >mdi-credit-card</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        4 Débito
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-btn
                                  @click="close"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-6"
                                  >Fechar
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            transition="fade"
                            v-if="loading === 'man_typing'"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #2cc4ea;">
                                  {{ methodsConverted[this.indexByArrow] }}
                                </h2>
                                <span class="grey--text fonte">
                                  Informe o valor abaixo
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <div class="pb-6">
                                  <h1 style="color: #2cc4ea;" class="fonte">
                                    {{
                                      $helper.formataSaldo(
                                        valueToPayInSelectedMethod || 0
                                      )
                                    }}
                                  </h1>
                                </div>
                                <v-flex xs12>
                                  <v-text-field
                                    prefix="R$"
                                    label="Informe o valor"
                                    hint="Informe todo ou parte do valor"
                                    clearable
                                    v-mask="[
                                      '#.##',
                                      '##.##',
                                      '###.##',
                                      '####.##',
                                      '#####.##'
                                    ]"
                                    v-model="valueToPayInSelectedMethod"
                                  ></v-text-field>
                                </v-flex>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="step = 1"
                                  dark
                                  text
                                  class="fonte animate__delay-2s mr-2 animate__fadeIn grey--text text-capitalize mt-3"
                                >
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-chevron-left</v-icon
                                  >
                                  voltar
                                </v-btn>
                                <v-btn
                                  @click="reportValueReceived"
                                  dark
                                  :color="$theme.third"
                                  class="fonte animate__delay-2s animate__fadeIn white--text text-capitalize mt-3"
                                >
                                  Pagar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            transition="fade"
                            v-if="loading === 'success'"
                            style="min-height: 17vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #2cc4ea;">
                                  Pagamento
                                </h2>
                                <span class="grey--text fonte">
                                  Esta compra foi integralmente paga
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal wrap"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <div class="pb-6">
                                  <h1 style="color: #2cc4ea;" class="fonte">
                                    {{
                                      $helper.formataSaldo(
                                        get_compra.compra.total_recebido
                                      )
                                    }}
                                  </h1>
                                  <h3
                                    class="font-weight-light orange--text"
                                    v-if="get_compra.compra.troco"
                                  >
                                    troco:
                                    {{
                                      $helper.formataSaldo(
                                        get_compra.compra.troco
                                      )
                                    }}
                                  </h3>
                                </div>
                                <v-flex class="fonte" xs12>
                                  <v-list nav class="pa-0 ma-0" dense>
                                    <template
                                      v-for="(payment,
                                      i) in get_compra.historico_de_pagamento"
                                    >
                                      <v-list-item
                                        v-show="payment.valor"
                                        :key="`payment-${i}`"
                                      >
                                        <v-avatar
                                          size="30"
                                          class="mr-2"
                                          :color="$theme.third"
                                        >
                                          <span
                                            class="white--text font-weight-bold"
                                          >
                                            {{ i }}
                                          </span>
                                        </v-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            class="green--text font-weight-bold"
                                          >
                                            {{
                                              $helper.formataSaldo(
                                                payment.valor
                                              )
                                            }}
                                            em {{ payment.forma_de_pagamento }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle>
                                            {{
                                              $moment(payment.data).format(
                                                "DD/MM [às] HH:mm"
                                              )
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider
                                        :key="`divider--${i}`"
                                      ></v-divider>
                                    </template>
                                  </v-list>
                                </v-flex>
                              </div>
                              <!-- <div class="expande-horizontal">
                                <v-btn
                                  @click="close"
                                  dark
                                  :color="$theme.secondary"
                                  class="fonte animate__delay-2s animate__fadeIn white--text mt-3"
                                >
                                  Fechar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div> -->
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="updateStatusToFinish(get_compra)"
                                  dark
                                  :color="$theme.secondary"
                                  class="fonte animate__delay-2s animate__fadeIn white--text mt-6"
                                  >Fechar
                                  <v-icon size="16" class="mt-1" color="white"
                                    >mdi-check-circle-outline</v-icon
                                  >
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="
                                    updateStatusToFinish(
                                      get_compra,
                                      (print = true)
                                    )
                                  "
                                  dark
                                  :color="$theme.secondary"
                                  class="fonte animate__delay-2s animate__fadeIn white--text mt-6"
                                  >Fechar e imprimir
                                  <v-icon size="16" class="mt-1" color="white"
                                    >mdi-check-circle-outline</v-icon
                                  >
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                  </div>
                </v-card>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";
// import { EventBus } from "@/main.js";
export default {
  props: {
    updateStatusToFinish: {
      type: Function,
      default: () => {}
    },
    limparComanda: {
      type: Function,
      default: () => {}
    },
    payment_sys_config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      methods: ["dinheiro", "pix", "credito", "debito", "SCN"],
      methodsConverted: ["Dinheiro", "Pix", "Crédito", "Débito", "SCN"],
      modal: false,
      valueToPayInSelectedMethod: "",
      loading: "",
      step: 1,
      indexByArrow: 0,
      remainingToPay: false,
      type: "" //payment_method_selected
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_compra"])
  },
  methods: {
    ...mapActions(["listar_gestordepedidos", "calcula_valor_total_da_compra"]),
    selectPaymentMethod(method) {
      this.type = method;
      let remainingToPay = 0;
      // valor que ainda falta para pagar
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_compra.compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
      }
      if (this.get_compra.compra[this.type]) {
        if (this.get_compra.compra[this.type] > 0) {
          this.get_compra.compra.total_recebido =
            this.get_compra.compra.total_recebido -
            parseFloat(this.get_compra.compra[this.type]);
        }
      }
      remainingToPay =
        parseFloat(this.get_compra.preco_total) -
        (this.get_compra.total_recebido || 0);

      if (remainingToPay === 0) {
        this.step = 3;
      } else {
        this.valueToPayInSelectedMethod = remainingToPay.toFixed(2);
        this.step = 2;
      }
    },
    reportValueReceived() {
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_compra.compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
        this.pay();
      }
    },
    pay() {
      this.updatePayment();
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_compra)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_compra", {
            ...res.data,
            ...res.data.compra,
            compra: res.data.compra,
            status_do_pagamento: res.data.status_do_pagamento,
            status_do_pedido: res.data.status_do_pedido
          });
          if (res.data.status_do_pagamento == "pago") {
            this.step = 3;
            this.setLoading("success");
          } else {
            this.step = 1;
          }
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
      }, 300);
    },
    open() {
      this.modal = false;
      this.calcula_valor_total_da_compra();
      this.modal = true;

      if (this.get_compra.status_do_pagamento === "pago") {
        this.setLoading("success");
        this.step = 3;
      } else {
        setTimeout(() => {
          this.setLoading("man_typing");
          this.step = 1;
        }, 100);
      }
    },
    close() {
      this.modal = false;
    },
    stepBack() {
      if (this.step === 1) {
        this.close();
      } else {
        this.step--;
      }
    }
  },
  mounted() {}
};
</script>

<style>
.paymentMethodPreSelected {
  background: #f0f0f0;
  border-radius: 6px;
}
</style>
